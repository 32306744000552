<template>
  <b-card no-body>
    <div class="m-2">
      <b-form-row class="justify-content-between">
        <b-col
          cols="4"
          md="2"
          class="d-none d-sm-block align-items-center justify-content-left"
        >
          <v-select
            label="title"
            required
            v-model="perPage"
            :options="perPageOptions"
            @input="setPerPageSelected"
            :clearable="false"
          />
        </b-col>
        <b-col cols="12" md="8">
          <div class="form-row justify-content-end">
            <div class="col-md-4">
              <b-form-input
                type="search"
                class="d-inline-block"
                placeholder="E-mail"
                id="filterInput"
                v-model="filters.email" />
            </div>
            <div class="col-md-4">
              <b-form-input
                type="search"
                class="d-inline-block"
                placeholder="Nome"
                id="filterInput"
                v-model="filters.name" />
            </div>
            <div class="col-md-1">
              <button @click="search" style="padding: 14px!important;" type="button" class="btn btn-secondary btn-block">
                <i v-if="!btnSearch" class="bi bi-search"></i>
                <b-spinner v-else small variant="light" />
              </button>
            </div>
          </div>            
        </b-col>
      </b-form-row>
    </div>

    <b-table
      class="position-relative"
      :items="dataItems"
      responsive
      :fields="fields"
      show-empty
      sticky-header
      striped
      hover
      empty-text="Nenhum registro encontrado"
    >
      <template #cell(first_name)="data">
        <b-link v-if="data.item.customer_name" :to="{ name: 'customers-edit', params: { uuid: data.item.customer_uuid}}">
          <i class="bi bi-box-arrow-up-right"></i>
          {{ data.item.customer_name }}
        </b-link>
        <span v-else>
          {{ data.item.first_name }}
        </span>
      </template>
      <template #cell(email)="data">
        {{ data.item.email }}
      </template>
      <template #cell(created_at)="data">
        <span v-if="data.item.created_at">
          {{ data.item.created_at | datePT(true) }}
        </span>
      </template>
      <template #cell(action)="data">
        <feather-icon
          icon="EyeIcon"
          class="cursor-pointer cursor"
          size="16"
          @click="modalDetails(data.item.uuid)"
        />
      </template>
    </b-table>

    <CustomPaginateTable
      :rows-table="rowsTable"
      :current-page="currentPage"
      :per-page="perPage"
      :total-rows="totalRows"
    />

    <b-modal
      id="showLead"
      modal-class="modal-edit"
      title="Detalhes do lead"
      hide-footer
      centered
      size="md"
    >
      <b-card-text>
        <h5 class="mb-2"><strong>Página:</strong> {{ item.page_title }}</h5>
        <div class="form-row">
          <b-col cols="12" md="6">
            <h6 class="mb-2 text-primary">Dados do lead</h6>
            <h6 class="mb-25">
              <strong>Nome:</strong>
              {{ item.first_name }} {{ item.last_name }}
            </h6>
            <p class="card-text mb-25">
              <strong>Contato:</strong>
              {{ item.phone }}
            </p>
            <p class="card-text mb-25">
              <strong>E-mail:</strong>
              {{ item.email }}
            </p>
          </b-col>

          <b-col md="6" cols="12">
            <div>
              <h6 class="mb-2 text-primary">Interesse</h6>   
              <h6 class="mb-25">
                <strong>Sessão:</strong>
                {{ item.page_type }}
              </h6>
              <p class="card-text mb-25">
                <strong>Unidade:</strong>
                {{ item.unit }}
              </p>
              <p class="card-text mb-25">
                <strong>Turno:</strong>
                {{ item.shift }}
              </p>
              <p class="card-text mb-25">
                <strong>Horário:</strong>
                {{ item.time }}
              </p>
            </div>
          </b-col>
          <div class="col-12">
            <hr>
          </div>
          <div class="col-12">
            <strong>Data:</strong> {{ item.created_at | datePT(true) }}
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  VBTooltip,
  BTooltip,
  BPagination,
  BModal,
  BFormGroup,
  BForm,
  BCardText,
  BFormRow,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";

export default {
  components: {
    BCard,
    BSpinner,
    CustomPaginateTable,
    BFormRow,
    VBTooltip,
    BTooltip,
    BCardText,
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    BForm,
    vSelect,
  },
  data() {
    return {
      filters: {
        name: '',
        email: '',
        origin: 'passport',
      },
      btnSearch: false,
      submited: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      fields: [
        {
          key: "first_name",
          label: "Nome",
        },
        {
          key: "prefer_to_study",
          label: "Preferência",
          class: "text-center",
        },
        {
          key: "email",
          label: "E-mail",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Cadastrado",
          class: "text-center",
          thStyle: "width: 200px",
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 100px",
        },
      ],
      dataItems: [],
      item: {
        uuid: "",
        page_title: "",
        page_type: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        popup: "",
        prefer_to_study: "",
        unit: "",
        shift: "",
        time: "",
        status: "",
        created_at: "",
        origin: "",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    search(){
      this.btnSearch = true;
      this.getData();
    },
    modalDetails(uuid) {
      this.$store.dispatch("Customer/lead", uuid).then((res) => {
        this.item = res;
      });

      this.item.uuid = uuid;
      this.$bvModal.show("showLead");
    },
    async getData() {
      this.$store
        .dispatch("Customer/leads", {
          currentPage: this.currentPage,
          perPage: this.perPage,
          filters: this.filters,
        })
        .then((res) => {
          if (res) {
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.currentPage = res.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
  },
  mounted() {
    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>
