var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "div",
        { staticClass: "m-2" },
        [
          _c(
            "b-form-row",
            { staticClass: "justify-content-between" },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-none d-sm-block align-items-center justify-content-left",
                  attrs: { cols: "4", md: "2" },
                },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      options: _vm.perPageOptions,
                      clearable: false,
                    },
                    on: { input: _vm.setPerPageSelected },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "12", md: "8" } }, [
                _c("div", { staticClass: "form-row justify-content-end" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("b-form-input", {
                        staticClass: "d-inline-block",
                        attrs: {
                          type: "search",
                          placeholder: "E-mail",
                          id: "filterInput",
                        },
                        model: {
                          value: _vm.filters.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "email", $$v)
                          },
                          expression: "filters.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("b-form-input", {
                        staticClass: "d-inline-block",
                        attrs: {
                          type: "search",
                          placeholder: "Nome",
                          id: "filterInput",
                        },
                        model: {
                          value: _vm.filters.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name", $$v)
                          },
                          expression: "filters.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-md-1" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary btn-block",
                        staticStyle: { padding: "14px!important" },
                        attrs: { type: "button" },
                        on: { click: _vm.search },
                      },
                      [
                        !_vm.btnSearch
                          ? _c("i", { staticClass: "bi bi-search" })
                          : _c("b-spinner", {
                              attrs: { small: "", variant: "light" },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-table", {
        staticClass: "position-relative",
        attrs: {
          items: _vm.dataItems,
          responsive: "",
          fields: _vm.fields,
          "show-empty": "",
          "sticky-header": "",
          striped: "",
          hover: "",
          "empty-text": "Nenhum registro encontrado",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(first_name)",
            fn: function (data) {
              return [
                data.item.customer_name
                  ? _c(
                      "b-link",
                      {
                        attrs: {
                          to: {
                            name: "customers-edit",
                            params: { uuid: data.item.customer_uuid },
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "bi bi-box-arrow-up-right" }),
                        _vm._v(" " + _vm._s(data.item.customer_name) + " "),
                      ]
                    )
                  : _c("span", [
                      _vm._v(" " + _vm._s(data.item.first_name) + " "),
                    ]),
              ]
            },
          },
          {
            key: "cell(email)",
            fn: function (data) {
              return [_vm._v(" " + _vm._s(data.item.email) + " ")]
            },
          },
          {
            key: "cell(created_at)",
            fn: function (data) {
              return [
                data.item.created_at
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("datePT")(data.item.created_at, true)) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(action)",
            fn: function (data) {
              return [
                _c("feather-icon", {
                  staticClass: "cursor-pointer cursor",
                  attrs: { icon: "EyeIcon", size: "16" },
                  on: {
                    click: function ($event) {
                      return _vm.modalDetails(data.item.uuid)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("CustomPaginateTable", {
        attrs: {
          "rows-table": _vm.rowsTable,
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          "total-rows": _vm.totalRows,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "showLead",
            "modal-class": "modal-edit",
            title: "Detalhes do lead",
            "hide-footer": "",
            centered: "",
            size: "md",
          },
        },
        [
          _c("b-card-text", [
            _c("h5", { staticClass: "mb-2" }, [
              _c("strong", [_vm._v("Página:")]),
              _vm._v(" " + _vm._s(_vm.item.page_title)),
            ]),
            _c(
              "div",
              { staticClass: "form-row" },
              [
                _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                  _c("h6", { staticClass: "mb-2 text-primary" }, [
                    _vm._v("Dados do lead"),
                  ]),
                  _c("h6", { staticClass: "mb-25" }, [
                    _c("strong", [_vm._v("Nome:")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.item.first_name) +
                        " " +
                        _vm._s(_vm.item.last_name) +
                        " "
                    ),
                  ]),
                  _c("p", { staticClass: "card-text mb-25" }, [
                    _c("strong", [_vm._v("Contato:")]),
                    _vm._v(" " + _vm._s(_vm.item.phone) + " "),
                  ]),
                  _c("p", { staticClass: "card-text mb-25" }, [
                    _c("strong", [_vm._v("E-mail:")]),
                    _vm._v(" " + _vm._s(_vm.item.email) + " "),
                  ]),
                ]),
                _c("b-col", { attrs: { md: "6", cols: "12" } }, [
                  _c("div", [
                    _c("h6", { staticClass: "mb-2 text-primary" }, [
                      _vm._v("Interesse"),
                    ]),
                    _c("h6", { staticClass: "mb-25" }, [
                      _c("strong", [_vm._v("Sessão:")]),
                      _vm._v(" " + _vm._s(_vm.item.page_type) + " "),
                    ]),
                    _c("p", { staticClass: "card-text mb-25" }, [
                      _c("strong", [_vm._v("Unidade:")]),
                      _vm._v(" " + _vm._s(_vm.item.unit) + " "),
                    ]),
                    _c("p", { staticClass: "card-text mb-25" }, [
                      _c("strong", [_vm._v("Turno:")]),
                      _vm._v(" " + _vm._s(_vm.item.shift) + " "),
                    ]),
                    _c("p", { staticClass: "card-text mb-25" }, [
                      _c("strong", [_vm._v("Horário:")]),
                      _vm._v(" " + _vm._s(_vm.item.time) + " "),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-12" }, [_c("hr")]),
                _c("div", { staticClass: "col-12" }, [
                  _c("strong", [_vm._v("Data:")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("datePT")(_vm.item.created_at, true)) +
                      " "
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }